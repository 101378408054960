import React, { Fragment } from 'react';
import { Box, Flex, Heading, Text } from '@fast-ai/ui-components';
import LinkButton from '@fast-ai/ui/LinkButton';
// import GraphCityImage from '@fast-ai/ui/GraphCityImage';
import { FormattedMessage } from 'gatsby-theme-fast-ai-sidebar';

import m from '../../intl/messages/viewLanding';
import r from '../../intl/messages/routes';
import n from '../../intl/messages/navigation';
import { Link, RichFormattedMessage } from '../../intl';
import HeroZooImage from '../../ui/HeroZooImage';
import { CompaniesBrands } from '../../ui';

const styles = {
	heroHeading: {
		fontSize: [7, 7, 8],
		mb: [2, 2, 3],
		position: 'relative',
		lineHeight: 1.1,
	},
	perex: {
		maxWidth: '30rem',
		mb: [2, 2, 3],
		fontSize: [2, 2, 3],
		mx: ['auto', 'auto', 0],
	},
	actions: {
		display: 'flex',
		mb: [2, 2, 3],
		justifyContent: ['center', 'center', 'flex-start'],
		'a + button, a + style + button': {
			ml: 2,
		},
	},
	cta: {
		py: [2, 3],
		px: [3, 4],
	},
};
const HeroSection = ({ location }) => (
	<Fragment>
		<Box
			as="section"
			sx={{
				px: t => t.grid.gutters,
				py: [4, 4, 6],
				mx: 'auto',
				flexGrow: 1,
				position: 'relative',
				width: '100%',
				maxWidth: ['100%', '100%', '70em', '82em'],
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				flexWrap: 'wrap',
			}}
		>
			<Box
				sx={{
					display: ['block', 'block', 'flex'],
					flexDirection: ['column', 'column', 'row'],
					flexGrow: 1,
					width: '100%',
					flexShrink: 0,
					alignItems: 'center',
					flexWrap: 'wrap',
					textAlign: ['center', 'center', 'left'],
					zIndex: 1,
					mb: 7,
				}}
			>
				<Flex sx={{ width: ['100%', '100%', '50%'], flexDirection: 'column' }}>
					<Heading sx={styles.heroHeading}>
						<RichFormattedMessage {...m.heroHeading} />
					</Heading>
					<Text as="p" sx={styles.perex}>
						<RichFormattedMessage {...m.heroPerex} />
					</Text>
					<Box sx={styles.actions}>
						<LinkButton
							as={Link}
							variant="primary"
							to={r.scheduleDemo}
							sx={styles.cta}
						>
							<Text sx={{ color: 'inherit' }}>
								<FormattedMessage {...n.scheduleDemo} />
							</Text>
						</LinkButton>
					</Box>
				</Flex>

				<Box
					sx={{
						width: ['100%', '100%', '50%'],
					}}
				>
					<Box
						sx={{
							maxWidth: 600,
							mx: 'auto',
							position: 'relative',
							zIndex: 0,
						}}
					>
						<HeroZooImage />
					</Box>
				</Box>
			</Box>

			<CompaniesBrands location={location} />
		</Box>
	</Fragment>
);

// <Box
// 	sx={{
// 		height: 'auto',
// 		width: ['100%', '550px', '500px', '550px'],
// 		position: ['relative', 'relative', 'absolute'],
// 		bottom: '-10px',
// 		right: [0, 0, '50px'],
// 		mx: 'auto',
// 		mt: ['-100px', '-100px', '-150px'],
// 		pointerEvents: 'none',
// 		zIndex: 0,
// 	}}
// >
// 	<GraphCityImage />
// </Box>
export default HeroSection;
