import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-theme-fast-ai-sidebar';
import { Box, Col, Heading, IconButton, Row } from '@fast-ai/ui-components';
import PlayIcon from '@fast-ai/dashboard-icons/src/Play';
import { graphql, useStaticQuery } from 'gatsby';

import { FocusIt, Section } from '../../ui';
import { RichFormattedMessage } from '../../intl';
import m from '../../intl/messages/viewLanding';

const VIDEO_ID = 'FhI3p94z9yI';

const DrVideoSection = ({
	width = 560,
	height = 315,
	sx,
	variant = 'stripesSecondary',
}) => {
	const data = useStaticQuery(graphql`
		{
			videoPlaceholder: file(relativePath: { eq: "video_placeholder.png" }) {
				childImageSharp {
					gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
				}
			}
		}
	`);
	const [showYoutubePlayer, setShowYoutubePlayer] = useState(false);
	return (
		<Section variant={variant} sx={sx}>
			<Row>
				<Col span={12}>
					<Heading>
						<RichFormattedMessage {...m.drVideoHeading} />
					</Heading>
				</Col>
			</Row>
			<Row>
				<Col span={12}>
					<Box
						sx={{
							maxWidth: '800px',
							width: '100%',
							mx: 'auto',
						}}
					>
						{showYoutubePlayer ? (
							<Box
								sx={{
									position: 'relative',
									width: '100%',
									height: 0,
									paddingBottom: `${(height / width) * 100}%`,
									border: theme => `8px dashed ${theme.colors.primary}`,
								}}
							>
								<Box
									sx={{
										position: 'absolute',
										left: 0,
										top: 0,
										bottom: 0,
										right: 0,
										width: '100%',
										height: '100%',
									}}
									as="iframe"
									src={`https://www.youtube.com/embed/${VIDEO_ID}?autoplay=1&enablejsapi=1`}
									title="YouTube video player"
									frameBorder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
								/>
							</Box>
						) : (
							<Box
								sx={{
									position: 'relative',
									border: theme => `8px dashed ${theme.colors.primary}`,
									'.gatsby-image-wrapper': {
										cursor: 'pointer',
									},
								}}
								onClick={() => {
									setShowYoutubePlayer(true);
								}}
							>
								<GatsbyImage
									image={data.videoPlaceholder.childImageSharp.gatsbyImageData}
									alt="video"
									title="video"
								/>
								<FocusIt
									sx={{
										m: 0,
										p: 0,
										width: 80,
										height: 80,
										backgroundColor: 'primary',
										borderRadius: 'circle',
										verticalAlign: 'middle',
										cursor: 'pointer',
										color: 'white',
									}}
								>
									{animationProps => (
										<Box
											sx={{
												transform: 'translate(-50%, -50%)',
												display: 'inline-box',
												position: 'absolute',
												top: '50%',
												left: '50%',
											}}
										>
											<IconButton {...animationProps}>
												<PlayIcon size={24} />
											</IconButton>
										</Box>
									)}
								</FocusIt>
							</Box>
						)}
					</Box>
				</Col>
			</Row>
		</Section>
	);
};

export default DrVideoSection;
