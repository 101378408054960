import React from 'react';
import { Box, Col, Heading, Row } from '@fast-ai/ui-components';
import UsersIcon from '@fast-ai/dashboard-icons/src/UsersAndCareers1Asset20';
import RulerIcon from '@fast-ai/dashboard-icons/src/Ruler';
import PlugIcon from '@fast-ai/dashboard-icons/src/Programming1Asset94';
import { Paragraph } from 'gatsby-theme-fast-ai-sidebar/src/mdxComponents';

import { Section, TextIn } from '../../ui';
import m from '../../intl/messages/viewLanding';
import { RichFormattedMessage } from '../../intl';

const styles = {
	row: {
		flexWrap: 'wrap',
		textAlign: ['left', 'left', 'center'],
		mb: 6,
	},
	descriptionCol: {
		textAlign: 'left',
	},
};

const data = [
	{
		iconsStyles: {
			'circle:nth-of-type(2),path:nth-of-type(3)': {
				stroke: 'primary',
			},
		},
		renderIcon: UsersIcon,
		heading: <RichFormattedMessage {...m.drExplanationVisitorsHeading} />,
		subHeading: <RichFormattedMessage {...m.drExplanationVisitorsSubheading} />,
		description: (
			<RichFormattedMessage {...m.drExplanationVisitorsDescription} />
		),
	},
	{
		iconsStyles: {
			color: 'body',
			'.pen': {
				fill: 'primary',
			},
		},
		renderIcon: RulerIcon,
		heading: <RichFormattedMessage {...m.drExplanationEngineHeading} />,
		subHeading: <RichFormattedMessage {...m.drExplanationEngineSubheading} />,
		description: <RichFormattedMessage {...m.drExplanationEngineDescription} />,
	},
	{
		iconsStyles: {
			color: 'primary',
			'path:nth-of-type(2)': { stroke: 'body' },
		},
		renderIcon: PlugIcon,
		heading: <RichFormattedMessage {...m.drExplanationOnboardingHeading} />,
		subHeading: (
			<RichFormattedMessage {...m.drExplanationOnboardingSubheading} />
		),
		description: (
			<RichFormattedMessage {...m.drExplanationOnboardingDescription} />
		),
	},
];
const DrExplanationSection = ({ sx, variant = 'stripesSecondary' }) => (
	<Section variant={variant} sx={sx}>
		<Row>
			<Col span={12}>
				<Heading>
					<RichFormattedMessage {...m.drExplanationHeading} />
				</Heading>
			</Col>
		</Row>
		{data.map(
			(
				{ heading, subHeading, description, renderIcon: Icon, iconsStyles },
				i
			) => (
				<TextIn
					sx={{
						...styles.row,
						flexDirection: i % 2 === 0 ? 'row' : 'row-reverse',
						alignItems: 'center',
					}}
					key={i}
				>
					{animationProps => (
						<Row {...animationProps}>
							<Col span={[12, 12, 3, 2]}>
								<Box
									as={Icon}
									sx={{
										display: ['none', 'none', 'inline-block'],
										...iconsStyles,
									}}
									size={128}
								/>
								<Box
									as={Icon}
									sx={{
										display: ['inline-block', 'inline-block', 'none'],
										...iconsStyles,
									}}
									size={64}
								/>
							</Col>
							<Col span={[12, 12, 9, 10]} sx={styles.descriptionCol}>
								<Heading sx={{ mb: [1, 1, 1] }} variant="subHeading1">
									{heading}
								</Heading>

								<Heading
									variant="subHeading2"
									as="h2"
									sx={{ color: 'primary', mb: [2, 2, 2] }}
								>
									{subHeading}
								</Heading>

								<Paragraph>{description}</Paragraph>
							</Col>
						</Row>
					)}
				</TextIn>
			)
		)}
	</Section>
);
export default DrExplanationSection;
