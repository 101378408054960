import React from 'react';
import PropTypes from 'prop-types';
import { Box, useDebouncedCallback } from '@fast-ai/ui-components';

const ResponsiveIcon = ({
	renderIcon: Icon,
	iconRef,
	sx,
	size: sizeProp = [64, 64, 96],
}) => {
	const refs = React.useRef({});

	const size = Array.isArray(sizeProp) ? sizeProp : [sizeProp];

	const [resizeHandler] = useDebouncedCallback(() => {
		if (!iconRef) {
			return;
		}
		for (let i = 0; i < size.length; i++) {
			if (refs.current && refs.current[i]) {
				iconRef(refs.current[i]);
			}
		}
	}, 250);

	React.useEffect(() => {
		window.addEventListener('resize', resizeHandler);
		return () => {
			window.removeEventListener('resize', resizeHandler);
		};
	}, [resizeHandler]);

	const refCallback = i => node => {
		if (!iconRef) {
			return;
		}

		if (size[i]) {
			iconRef(node);

			refs.current[i] = node;
		}
	};

	return (
		<React.Fragment>
			{size.map((s, i) => {
				const display = Array(size.length).fill('none');
				display[i] = 'inline-block';
				return (
					<Box
						ref={refCallback(i)}
						key={`${size}_${i}`}
						as={Icon}
						sx={{
							display,
							...sx,
						}}
						size={s}
					/>
				);
			})}
		</React.Fragment>
	);
};
ResponsiveIcon.propTypes = {
	iconRef: PropTypes.func,
	renderIcon: PropTypes.elementType,
	size: PropTypes.any,
};
ResponsiveIcon.displayName = 'ResponsiveIcon';
export default ResponsiveIcon;
