import React from 'react';
import { Col, Heading, Row } from '@fast-ai/ui-components';
import ZoeBaasAnimation from '@fast-ai/ui/ZoeBaasAnimation';
import ZoeDrAnimation from '@fast-ai/ui/ZoeDrAnimation';

import { Cards, Section } from '../../ui';
import m from '../../intl/messages/viewLanding';
import r from '../../intl/messages/routes';
import { RichFormattedMessage } from '../../intl';

const products = [
	{
		icon: <ZoeDrAnimation size={128} />,
		to: r.dr,
		heading: <RichFormattedMessage {...m.productsDrHeading} />,
		description: <RichFormattedMessage {...m.productsDrDescription} />,
	},
	{
		icon: <ZoeBaasAnimation size={128} />,
		to: r.baas,
		heading: <RichFormattedMessage {...m.productsBaasHeading} />,
		description: <RichFormattedMessage {...m.productsBaasDescription} />,
	},
];
const ProductsSection = ({ variant, sx }) => (
	<Section sx={sx} variant={variant}>
		<Row>
			<Col span={12}>
				<Heading>
					<RichFormattedMessage {...m.productsHeading} />
				</Heading>
			</Col>
		</Row>
		<Cards items={products} />
	</Section>
);
export default ProductsSection;
