/* eslint-disable react/prop-types,react/display-name */
import React, { forwardRef } from 'react';
import { Box, Col, Heading, Row, Text } from '@fast-ai/ui-components';
import RealTimeAnalysisIcon from '@fast-ai/dashboard-icons/src/BusinessAndFinance3Asset25';
import FingerprintIcon from '@fast-ai/dashboard-icons/src/Fingerprint';
import SelfLearningIcon from '@fast-ai/dashboard-icons/src/BusinessAndFinance1Asset25';

import m from '../../intl/messages/viewLanding';
import { Section, TextIn } from '../../ui';
import { RichFormattedMessage } from '../../intl';

const differencesData = [
	{
		icon: RealTimeAnalysisIcon,

		heading: <RichFormattedMessage {...m.differencesRealtimeHeading} />,
		description: <RichFormattedMessage {...m.differencesRealtimeDescription} />,
	},
	{
		icon: FingerprintIcon,
		heading: <RichFormattedMessage {...m.differencesFingerprintHeading} />,
		description: (
			<RichFormattedMessage {...m.differencesFingerprintDescription} />
		),
	},
	{
		icon: SelfLearningIcon,
		heading: <RichFormattedMessage {...m.differencesLearningHeading} />,
		description: <RichFormattedMessage {...m.differencesLearningDescription} />,
	},
];
const DifferenceItem = forwardRef(
	({ sx, heading, description, icon: Icon }, ref) => (
		<Col
			ref={ref}
			className="differences-column"
			span={[12, 12, 4]}
			sx={{
				textAlign: ['left', 'left', 'center'],
				...sx,
			}}
		>
			<Box
				as={Icon}
				sx={{ display: ['none', 'none', 'inline-block'] }}
				size={128}
			/>
			<Box
				as={Icon}
				sx={{ display: ['inline-block', 'inline-block', 'none'] }}
				size={64}
			/>
			<Heading variant="subHeading2">{heading}</Heading>
			<Text as="p">{description}</Text>
		</Col>
	)
);

const DifferencesSection = ({ sx, variant }) => (
	<Section sx={sx} variant={variant}>
		<Row>
			<Col span={12}>
				<Heading>
					<RichFormattedMessage {...m.differencesHeading} />
				</Heading>
			</Col>
		</Row>
		<Row
			sx={{
				flexWrap: 'wrap',
				svg: {
					color: 'primary',
				},
				'.differences-column + .differences-column': {
					mt: [5, 5, 0],
				},
			}}
		>
			{differencesData.map((props, i) => (
				<TextIn key={i} delay={`${i * 0.2}s`}>
					{animationProps => <DifferenceItem {...animationProps} {...props} />}
				</TextIn>
			))}
		</Row>
	</Section>
);
export default DifferencesSection;
