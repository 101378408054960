/* eslint-disable max-len */
import React, { Fragment } from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<Fragment>
		<g transform="scale(1.03 1.03)">
			<g
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
				strokeWidth="2"
				data-name="Layer 2"
			>
				<path d="M31 6.33l-2.39-5-5 2.4" />
				<path
					strokeDasharray="18 2 2 2 2 2"
					d="M28.62 1.35A30 30 0 010 22.33"
				/>
				<path d="M2 26.33h4v4H2zM10 24.33h4v6h-4zM18 22.33h4v8h-4zM26 15.33h4v15h-4z" />
			</g>
		</g>
	</Fragment>,
	32,
	'GraphsAndCharts2Asset22'
);
