/* eslint-disable react/prop-types,react/display-name */
import React from 'react';
import { Box, Col, Heading, Row, Text } from '@fast-ai/ui-components';
import { keyframes } from '@emotion/core';
import LinkButton from '@fast-ai/ui/LinkButton';
import ClickIcon from '@fast-ai/dashboard-icons/src/MouseClick';
import useJustIsInViewport from '@fast-ai/ui/utils/useJustIsInViewport';
import useEventCallback from '@fast-ai/ui/utils/useEventCallback';

import n from '../../intl/messages/navigation';
import r from '../../intl/messages/routes';
import m from '../../intl/messages/viewLanding';
import { Section } from '../../ui';
import { Link, RichFormattedMessage } from '../../intl';

const clickAnimation = keyframes`
0% { transform: scale(1,1); }
10% { transform: scale(1.5,1.5); }
20% { transform: scale(1.1,1.1); }
30% { transform: scale(1.4,1.4); }
40% { transform: scale(1.1,1.1); }
50% { transform: scale(1.3,1.3); }
60% { transform: scale(1,1); }
70% { transform: scale(1.2,1.2); }
80% { transform: scale(1,1); }
90% { transform: scale(1.2,1.2); }
`;

const mouseAnimation = keyframes`
0% { opacity: 0; transform: scale(0,0) translate(200%,20%); }
100% { opacity: 1; transform: scale(1,1) translate(0,0); }
`;

const MouseClicking = ({ shown }) => {
	const setPath = useEventCallback(node => {
		if (!node) {
			return;
		}
		const pathRipple = node.getElementsByClassName('ripple')?.[0];
		// const g = node.getElementsByTagName('g')?.[0];
		const BBox = pathRipple.getBBox();
		pathRipple.style.transformOrigin = `${BBox.x + BBox.height / 2}px ${BBox.y + BBox.width / 2
			}px`;
	});
	return (
		<Box
			ref={setPath}
			sx={{
				py: 4,
				color: 'blue',
				svg: {
					overflow: 'visible',
					// mouse
					'.cursor': {
						opacity: 0,
						transform: 'scale(0,0) translate(200%,20%)',
						fill: 'primary',
						...(shown && {
							animationName: mouseAnimation,
							animationDuration: '3s',
							animationIterationCount: '1',
							animationFillMode: 'both',
							animationTimingFunction: 'ease-out',
						}),
					},
					// ripple
					'.ripple': {
						fill: 'secondary',
						transform: 'scale(1,1)',
						animationName: clickAnimation,
						animationDuration: '4s',
						animationIterationCount: 'infinite',
						animationFillMode: 'both',
						animationTimingFunction: 'ease-in-out',
						stroke: 'secondary',
					},
				},
			}}
		>
			<ClickIcon size={128} />
		</Box>
	);
};
const styles = {
	actions: {
		display: 'flex',
		mb: [2, 2, 3, 3],
		justifyContent: ['center', 'center', 'flex-start'],
		'a + button': {
			ml: 2,
		},
	},
	bigButton: {
		py: [2, 3],
		px: [3, 4],
	},
};
const ContactSection = ({ sx, variant, headingProps, hideHeading }) => {
	const { ref: rootRef, shown } = useJustIsInViewport({
		threshold: 0.5,
	});
	return (
		<Section ref={rootRef} sx={sx} variant={variant}>
			<Row
				sx={{
					textAlign: 'center',
				}}
			>
				<Col span={12}>
					{!hideHeading && (
						<Heading {...headingProps}>
							<RichFormattedMessage {...m.contactsHeading} />
						</Heading>
					)}
					<MouseClicking shown={shown} />
					<Box sx={{ ...styles.actions, justifyContent: 'center' }}>
						<LinkButton
							as={Link}
							to={r.scheduleDemo}
							variant="primary"
							sx={styles.bigButton}
						>
							<Text sx={{ color: 'inherit' }}>
								<RichFormattedMessage {...n.scheduleDemo} />
							</Text>
						</LinkButton>
					</Box>
				</Col>
			</Row>
		</Section>
	);
};
export default ContactSection;
