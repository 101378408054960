import { defineMessages } from 'gatsby-theme-fast-ai-sidebar';

export default defineMessages({
	heroHeading: {
		id: 'view.landing.heroHeading',
		defaultMessage:
			'Fully <strong>individualized</strong>{br} experience through{br} AI Deep <strong>recommendation</strong>',
	},
	heroPerex: {
		id: 'view.landing.heroPerex',
		defaultMessage:
			'Improve conversion by displaying relevant items to each visitor individually. Self‑learning AI evaluates the visitor behavior in real‑time.',
	},
	drVideoHeading: {
		id: 'view.landing.drVideoHeading',
		defaultMessage:
			'Watch <strong>how</strong> Zoe<strong>.</strong>ai{br} recommendation <strong>works</strong>',
	},
	drExplanationHeading: {
		id: 'view.landing.drExplanationHeading',
		defaultMessage: 'Recommend <strong>relevant</strong> items',
	},
	drExplanationVisitorsHeading: {
		id: 'view.landing.drExplanationVisitorsHeading',
		defaultMessage: 'Individualize content for each visitor',
	},
	drExplanationVisitorsSubheading: {
		id: 'view.landing.drExplanationVisitorsSubheading',
		defaultMessage:
			'Increase revenue by 5-25% by showing visitors what they most likely want to see and buy',
	},
	drExplanationVisitorsDescription: {
		id: 'view.landing.drExplanationVisitorsDescription',
		defaultMessage:
			'Improve an offer on the homepage, detail, cart or any other part of the shopping process by showing the most relevant items individually chosen for each visitor.',
	},
	drExplanationEngineHeading: {
		id: 'view.landing.drExplanationEngineHeading',
		defaultMessage: 'Own AI engine',
	},
	drExplanationEngineSubheading: {
		id: 'view.landing.drExplanationEngineSubheading',
		defaultMessage:
			'Using digital body language and a true real-time personalization',
	},
	drExplanationEngineDescription: {
		id: 'view.landing.drExplanationEngineDescription',
		defaultMessage:
			'Visitors leave many behavior signals during their shopping and the AI engine enables us to recognize their intents and preferences. The engine dynamically analyzes current behavior within the session and offers relevant recommended items in true real-time.',
	},

	drExplanationOnboardingHeading: {
		id: 'view.landing.drExplanationOnboardingHeading',
		defaultMessage: 'Quick onboarding',
	},
	drExplanationOnboardingSubheading: {
		id: 'view.landing.drExplanationOnboardingSubheading',
		defaultMessage: 'Plug and play Software-as-a-Service',
	},
	drExplanationOnboardingDescription: {
		id: 'view.landing.drExplanationOnboardingDescription',
		defaultMessage:
			'A cloud-based service that is ready to be deployed and used. Easy onboarding and deployment are provided in the comprehensive documentation.',
	},
	drProcessHeading: {
		id: 'view.landing.drProcessHeading',
		defaultMessage:
			'How to Start with Zoe<strong>.</strong>ai<strong>?</strong>',
	},

	drProcessAccountHeading: {
		id: 'view.landing.drProcessAccountHeading',
		defaultMessage: 'Create an account',
	},
	drProcessAccountDescription: {
		id: 'view.landing.drProcessAccountDescription',
		defaultMessage:
			'The registration process is simple and easy, after filling in basic information, the account will be created.',
	},

	drProcessCatalogHeading: {
		id: 'view.landing.drProcessCatalogHeading',
		defaultMessage: 'Provide an item catalog',
	},
	drProcessCatalogDescription: {
		id: 'view.landing.drProcessCatalogDescription',
		defaultMessage:
			'Your users will receive recommended items picked from the catalog managed via our items API.',
	},
	drProcessCatalogAction: {
		id: 'view.landing.drProcessCatalogAction',
		defaultMessage: 'About Items API',
	},

	drProcessWidgetHeading: {
		id: 'view.landing.drProcessWidgetHeading',
		defaultMessage: 'Record user interactions',
	},
	drProcessWidgetDescription: {
		id: 'view.landing.drProcessWidgetDescription',
		defaultMessage:
			"Install JavaScript tracker to your application with a simple code snippet and start collecting users' behavior. Provide item feed through API.",
	},
	drProcessWidgetAction: {
		id: 'view.landing.drProcessWidgetAction',
		defaultMessage: 'Install tracker',
	},
	drProcessRecommendationsHeading: {
		id: 'view.landing.drProcessRecommendationsHeading',
		defaultMessage: 'Serve recommended items',
	},
	drProcessRecommendationsDescription: {
		id: 'view.landing.drProcessRecommendationsDescription',
		defaultMessage:
			'Fetch individual recommendations on the fly through Zoe.ai REST API.  {br} You control how the fetched results are presented to the user in your application.',
	},
	drProcessRecommendationsAction: {
		id: 'view.landing.drProcessRecommendationsAction',
		defaultMessage: 'Recommend items',
	},
	differencesHeading: {
		id: 'view.landing.differencesHeading',
		defaultMessage:
			'Why is Zoe<strong>.</strong>ai different<strong>?</strong>',
	},
	differencesRealtimeHeading: {
		id: 'view.landing.differencesRealtimeHeading',
		defaultMessage: 'In-session real-time analysis',
	},
	differencesRealtimeDescription: {
		id: 'view.landing.differencesRealtimeDescription',
		defaultMessage:
			'Our technology gains knowledge and personalizes on-the-fly within one user session and then improves the recommendations.',
	},
	differencesFingerprintHeading: {
		id: 'view.landing.differencesFingerprintHeading',
		defaultMessage: 'Digital body language',
	},
	differencesFingerprintDescription: {
		id: 'view.landing.differencesFingerprintDescription',
		defaultMessage:
			"Behavioral Signals help us to understand the shoppers' intent. Everyone is unique. We embrace it.",
	},

	differencesLearningHeading: {
		id: 'view.landing.differencesLearningHeading',
		defaultMessage: 'Self-learning AI',
	},
	differencesLearningDescription: {
		id: 'view.landing.differencesLearningDescription',
		defaultMessage:
			'Fine-tuned next-gen technology using deep neural networks that outperform generic engines. The more it is used, the better models are provided.',
	},

	drTimelineHeading: {
		id: 'view.landing.drTimelineHeading',
		defaultMessage:
			'Zoe<strong>.</strong>ai is <strong>nextgen</strong> {br} recommendation engine',
	},

	drTimelineGen0Description: {
		id: 'view.landing.drTimelineGen0Description',
		defaultMessage:
			'Basic personalization, but not in real-time. Do not adapt well, expert segmentation. Manual effort needed without additional gain.',
	},
	drTimelineGen0Heading: {
		id: 'view.landing.drTimelineGen0Heading',
		defaultMessage: 'Generation 0',
	},

	drTimelineGen1Description: {
		id: 'view.landing.drTimelineGen1Description',
		defaultMessage:
			'Basic recommendations and no personalization. Trending bestsellers. Simple, working, but do not reflect the visitor’s intentions and preferences.',
	},
	drTimelineGen1Heading: {
		id: 'view.landing.drTimelineGen1Heading',
		defaultMessage: 'Generation 1',
	},

	drTimelineGen2Description: {
		id: 'view.landing.drTimelineGen2Description',
		defaultMessage:
			'<strong>Self-learning</strong> Deep AI recommendation. Learning from behavioral data. Real-time shopper behavior is used. Automation and self-adaptation.',
	},
	drTimelineGen2Heading: {
		id: 'view.landing.drTimelineGen2Heading',
		defaultMessage: 'Deep recommendation',
	},
	contactsHeading: {
		id: 'view.landing.contactsHeading',
		defaultMessage:
			'Do you have any <strong>questions</strong>?{br} We are <strong>one click</strong> away.',
	},
	productsHeading: {
		id: 'view.landing.productsHeading',
		defaultMessage: 'Pick a <strong>Product</strong>',
	},
	productsDrHeading: {
		id: 'view.landing.productsDrHeading',
		defaultMessage: 'Deep recommendation',
	},
	productsDrDescription: {
		id: 'view.landing.productsDrDescription',
		defaultMessage:
			'Based on self-learning, AI models evaluate how visitors behave on the page and we can deliver personalized products for each visitor in real-time.',
	},
	productsBaasHeading: {
		id: 'view.landing.productsBaasHeading',
		defaultMessage: 'Behavior-as-a-service',
	},
	productsBaasDescription: {
		id: 'view.landing.productsBaasDescription',
		defaultMessage:
			'Use detailed behavioral signals  in real-time in order to reveal suspicious and fraudulent users during online loans or in insurance claims processes.',
	},
	referencesHeading: {
		id: 'view.landing.referencesHeading',
		defaultMessage: 'What do our customers say<strong>?</strong>',
	},
	refZiraDescription: {
		id: 'view.landing.refZiraDescription',
		// defaultMessage:
		defaultMessage:
			'<p>Together with <strong>Lundegaard</strong> we reviewed our solution, got useful tips and recommendations that potentially <b>saved us</b> from having serious production issues later.  Furthermore, we are now <b>confident</b> that we chose the right technology and approach for our solution.</p> <p>We were looking for <b>professional</b> and experienced consultants that have real-world <b>experience</b> with Apache Kafka and the guys from Lundegaard had proved themselves to be exactly that. They put in extraordinary effort in order to understand our solution and make it ready for production.</p>',
	},
	refHeurekaDescription: {
		id: 'view.landing.refHeurekaDescription',
		defaultMessage:
			"<p>Deploying <strong>Zoe.ai</strong> in the production verified that individual <b>recommendations</b> and a detailed understanding of our users' specific interests are the <b>right way</b> to improve engagement and final conversion. In an A/B test, <b>revenue</b> per session <b>increased</b> by up to 5% for the group of users who were offered products by Zoe.ai.</p>",
	},
	refPilulkaDescription: {
		id: 'view.landing.refPilulkaDescription',
		defaultMessage:
			'<p>Zoe.ai has helped us offer more relevant products to customers in the recommendation areas. This makes the use of these areas significantly more efficient. We are now looking at other places where we can incorporate AI.</p>',
	},
});
