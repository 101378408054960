import { useEffect, useState } from 'react';

const useTwoPassRender = () => {
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setMounted(true);
	}, []);

	return mounted;
};
export default useTwoPassRender;
