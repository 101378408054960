import React from 'react';
import { keyframes } from '@emotion/core';
import { Box } from '@fast-ai/ui-components';
import PropTypes from 'prop-types';
import ZoeBaasIcon from '@fast-ai/dashboard-icons/src/ZoeBaas';

import useEventCallback from './utils/useEventCallback';
import useTwoPassRender from './utils/useTwoPassRender';
import ResponsiveIcon from './ResponsiveIcon';

const qAnimation = keyframes`
0% { transform: scale(1,1); }
10% { transform: scale(1.5,1.5); }
20% { transform: scale(1.1,1.1); }
30% { transform: scale(1.4,1.4); }
40% { transform: scale(1.1,1.1); }
50% { transform: scale(1.3,1.3); }
60% { transform: scale(1,1); }
70% { transform: scale(1.2,1.2); }
80% { transform: scale(1,1); }
90% { transform: scale(1.2,1.2); }
`;
const ZoeBaasAnimation = ({ size = 256, sx, ...rest }) => {
	const isSecondPass = useTwoPassRender();
	const setPath = useEventCallback(node => {
		if (!node) {
			return;
		}
		const path = node.getElementsByClassName('q')?.[0];
		const BBox = path.getBBox();
		path.style.transformOrigin = `${BBox.x + BBox.height / 2}px ${
			BBox.y + BBox.width / 2
		}px`;
	});

	return (
		<Box
			sx={{
				color: 'primary',
				...sx,
			}}
			{...rest}
		>
			<ResponsiveIcon
				iconRef={setPath}
				sx={{
					overflow: 'visible',
					'.body': { fill: 'body' },
					'.q': {
						transform: 'scale(1,1)',
						animationName: isSecondPass ? qAnimation : null,
						animationDuration: '4s',
						animationDelay: '2s',
						animationIterationCount: 'infinite',
						animationFillMode: 'both',
						animationTimingFunction: 'ease-in-out',
						fill: 'secondary',
					},
				}}
				renderIcon={ZoeBaasIcon}
				size={size}
			/>
		</Box>
	);
};
ZoeBaasAnimation.propTypes = {
	size: PropTypes.any,
};
export default ZoeBaasAnimation;
