/* eslint-disable react/prop-types,react/display-name */
import React from 'react';
import { Box, Col, Container, Heading, Row } from '@fast-ai/ui-components';
import { Paragraph } from 'gatsby-theme-fast-ai-sidebar/src/mdxComponents';
import { StaticImage } from 'gatsby-plugin-image';
import Text from '@fast-ai/ui-components/Text';

import n from '../../intl/messages/navigation';
import r from '../../intl/messages/routes';
import m from '../../intl/messages/viewLanding';
import { RichFormattedMessage, useTranslateMessage } from '../../intl';
import { NavLink, TextIn } from '../../ui';

const ProcessItem = ({
	num,
	heading,
	action,
	description,
	image,
	className,
}) => (
	<TextIn delay={`${(num - 1) * 0.2}s`}>
		{animationProps => (
			<Col {...animationProps} className={className} span={[12, 12, 3]}>
				<Row>
					<Col span={[1, 1, 0]} />
					<Col span={[10, 10, 12]}>
						<Box
							sx={{
								display: 'flex',
								flexDirection: ['column', 'column', 'column'],
							}}
						>
							<Heading
								variant="subHeading1"
								sx={{
									fontSize: [5, 5, 5, 6],
									height: ['auto', 'auto', '120px', '110px'],
								}}
							>
								<Text
									as="div"
									sx={{
										py: [0, 0, 0, 0],
										mb: [0, 0, 0, 0],
										fontFamily: 'heading',
										color: 'primary',
									}}
								>
									{num}.
								</Text>
								{heading}
							</Heading>
							<Box sx={{ pr: [4, 6, 0] }}>{image}</Box>
						</Box>
						<Paragraph>{description}</Paragraph>
						<Paragraph>{action}</Paragraph>
					</Col>
				</Row>
			</Col>
		)}
	</TextIn>
);

const defaultHeading = <RichFormattedMessage {...m.drProcessHeading} />;
const DrProcessSection = ({
	heading = defaultHeading,
	sx,
	variant = 'stripesPrimary',
}) => {
	const translate = useTranslateMessage();
	const process = [
		{
			heading: <RichFormattedMessage {...m.drProcessAccountHeading} />,
			description: <RichFormattedMessage {...m.drProcessAccountDescription} />,
			action: (
				<NavLink to={r.scheduleDemo}>
					<RichFormattedMessage {...n.scheduleDemo} />
				</NavLink>
			),
			image: (
				<StaticImage
					placeholder="blurred"
					alt={translate(m.drProcessAccountHeading)}
					src="../../../content/assets/p1.png"
					layout="fullWidth"
				/>
			),
		},
		{
			image: (
				<StaticImage
					alt={translate(m.drProcessCatalogHeading)}
					placeholder="blurred"
					src="../../../content/assets/p2.png"
					layout="fullWidth"
				/>
			),
			heading: <RichFormattedMessage {...m.drProcessCatalogHeading} />,
			description: <RichFormattedMessage {...m.drProcessCatalogDescription} />,
			action: (
				<NavLink to="https://zoe.lundegaard.ai/docs/dr/learn/zoe-api/getting-started/items-import">
					<RichFormattedMessage {...m.drProcessCatalogAction} />
				</NavLink>
			),
		},
		{
			image: (
				<StaticImage
					placeholder="blurred"
					alt={translate(m.drProcessWidgetHeading)}
					src="../../../content/assets/p3.png"
					layout="fullWidth"
				/>
			),
			heading: <RichFormattedMessage {...m.drProcessWidgetHeading} />,
			description: <RichFormattedMessage {...m.drProcessWidgetDescription} />,
			action: (
				<NavLink to="https://zoe.lundegaard.ai/docs/dr/learn/s-analytics/getting-started">
					<RichFormattedMessage {...m.drProcessWidgetAction} />
				</NavLink>
			),
		},
		{
			image: (
				<StaticImage
					placeholder="blurred"
					alt={translate(m.drProcessRecommendationsHeading)}
					src="../../../content/assets/p4.png"
					layout="fullWidth"
				/>
			),
			heading: <RichFormattedMessage {...m.drProcessRecommendationsHeading} />,
			description: (
				<RichFormattedMessage {...m.drProcessRecommendationsDescription} />
			),
			action: (
				<NavLink to="https://zoe.lundegaard.ai/docs/dr/learn/s-analytics/getting-started">
					<RichFormattedMessage {...m.drProcessRecommendationsAction} />
				</NavLink>
			),
		},
	];

	return (
		<Box sx={sx} variant={variant}>
			<Container
				as="section"
				sx={{
					position: 'relative',
					// textAlign: 'center',
					py: [5, 5, 6],

					'.process-item + .process-item': {
						mt: [5, 5, 0, 0],
					},
				}}
			>
				<Row sx={{ textAlign: 'center' }}>
					<Col span={12}>
						<Heading>{heading}</Heading>
					</Col>
				</Row>
				<Row sx={{ flexWrap: 'wrap' }}>
					{process.map((props, i) => (
						<ProcessItem
							className="process-item"
							key={i}
							num={i + 1}
							{...props}
						/>
					))}
				</Row>
			</Container>
		</Box>
	);
};
export default DrProcessSection;
