/* eslint-disable max-len */
import React from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<React.Fragment>
		<g fill="currentcolor">
			<path
				className="cursor"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M57.3862 41.8779L29.6915 29.691L67.8262 116.352L80.4961 97.3694L111.126 128L128 111.126L97.3699 80.4956L116.352 67.8257L92.5918 57.37L89.4922 64.5229L100.199 69.2346L85.1375 79.2877L116.976 111.126L111.126 116.975L79.2882 85.137L69.2351 100.199L44.9014 44.9009L54.2866 49.0308L57.3862 41.8779ZM61.6092 52.2531L68.2282 55.1657L71.3278 48.0128L64.7088 45.1002L61.6092 52.2531ZM78.6503 51.2351L75.5507 58.388L82.1697 61.3006L85.2693 54.1477L78.6503 51.2351Z"
			/>
			<path
				className="ripple"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M36.1322 16.0583V-0.000427246H44.1616V16.0583H36.1322ZM14.6019 8.92377L25.9635 20.2853L20.2859 25.9629L8.92432 14.6014L14.6019 8.92377ZM71.3694 14.6014L60.0079 25.9629L54.3303 20.2853L65.6918 8.92377L71.3694 14.6014ZM16.0588 44.161H0.00012207V36.1317H16.0588V44.161ZM8.92432 65.6913L20.2859 54.3298L25.9635 60.0074L14.6019 71.3689L8.92432 65.6913Z"
			/>
		</g>
	</React.Fragment>,
	128,
	'MouseClick'
);
