/* eslint-disable max-len */
import React, { forwardRef } from 'react';

import SvgIcon from './Icon';

const Ruler = forwardRef((props, ref) => (
	<SvgIcon viewBox="0 0 32 32" ref={ref} {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.0021 17.8263L15.5021 19.3263L16.9163 17.9121L15.4163 16.4121L14.0021 17.8263ZM14.0021 14.9979L12.5879 16.4121L2 5.82422V30.41H26.5858L16.9163 20.7405L18.3305 19.3263L31.4142 32.41H0V0.995789L14.0021 14.9979Z"
			fill="currentcolor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6 15.488L16.9365 26.41H6V15.488ZM8 20.3119V24.41H12.1035L8 20.3119Z"
			fill="currentcolor"
		/>

		<path
			d="M1 13.41H4"
			stroke="currentcolor"
			strokeWidth="2"
			strokeMiterlimit="10"
		/>
		<path
			d="M1 17.41H4"
			stroke="currentcolor"
			strokeWidth="2"
			strokeMiterlimit="10"
		/>
		<path
			d="M1 21.41H4"
			stroke="currentcolor"
			strokeWidth="2"
			strokeMiterlimit="10"
		/>
		<path
			d="M1 25.41H4"
			stroke="currentcolor"
			strokeWidth="2"
			strokeMiterlimit="10"
		/>
		<path
			d="M7 31.41V28.41"
			stroke="currentcolor"
			strokeWidth="2"
			strokeMiterlimit="10"
		/>
		<path
			d="M11 31.41V28.41"
			stroke="currentcolor"
			strokeWidth="2"
			strokeMiterlimit="10"
		/>
		<path
			d="M15 31.41V28.41"
			stroke="currentcolor"
			strokeWidth="2"
			strokeMiterlimit="10"
		/>
		<path
			d="M19 31.41V28.41"
			stroke="currentcolor"
			strokeWidth="2"
			strokeMiterlimit="10"
		/>
		<path
			className="pen"
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.4715 2.2069L8.81268 1.26135L9.79971 7.86374L24.1733 22.2475C24.9233 22.9966 25.94 23.4173 27 23.4173C28.0598 23.4173 29.0763 22.9967 29.8263 22.2479C30.199 21.8764 30.4947 21.4349 30.6965 20.9489C30.8984 20.4627 31.0024 19.9414 31.0024 19.415C31.0024 18.8885 30.8984 18.3672 30.6965 17.881C30.4949 17.3954 30.1994 16.9542 29.8271 16.5828L29.8259 16.5816L15.4715 2.2069ZM28.4141 20.8316L29.12 21.54L28.4133 20.8324C28.0383 21.207 27.53 21.4173 27 21.4173C26.4703 21.4173 25.9616 21.2065 25.5867 20.8324L24.8753 20.1206L27.7097 17.2929L28.4124 17.9966L28.4141 17.9983C28.6006 18.1841 28.7485 18.4049 28.8495 18.648C28.9504 18.8911 29.0024 19.1518 29.0024 19.415C29.0024 19.6782 28.9504 19.9389 28.8495 20.182C28.7485 20.4251 28.6006 20.6458 28.4141 20.8316ZM26.2965 15.8777L14.5285 4.09307L11.1873 3.61861L11.6803 6.91622L23.4616 18.7059L26.2965 15.8777Z"
			fill="currentcolor"
		/>
	</SvgIcon>
));

Ruler.displayName = 'Ruler';

export default Ruler;
