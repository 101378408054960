/* eslint-disable max-len */
import React, { Fragment } from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<Fragment>
		<path
			fill="none"
			stroke="currentColor"
			strokeDasharray="21.11 3.84 1.92 0"
			strokeWidth="1.92"
			d="M16.537 16.471l.678-.679c.745-.744.745-1.969 0-2.714-.744-.744-1.969-.744-2.714 0-1.488 1.489-1.488 3.939 0 5.428 1.489 1.489 3.939 1.489 5.428 0 2.233-2.233 2.233-5.908 0-8.141-2.233-2.234-5.908-2.234-8.141 0-2.978 2.977-2.978 7.877 0 10.855 2.977 2.977 7.877 2.977 10.855 0 3.722-3.722 3.722-9.847 0-13.569-3.722-3.722-9.847-3.722-13.569 0-4.466 4.466-4.466 11.816 0 16.283 4.466 4.466 11.816 4.466 16.283 0 5.21-5.211 5.21-13.786 0-18.997-5.211-5.211-13.786-5.211-18.997 0-5.955 5.955-5.955 15.755 0 21.71 3.873 3.876 9.539 5.394 14.831 3.976"
		/>
	</Fragment>,
	32,
	'Fingerprint'
);
