import React from 'react';
import { keyframes } from '@emotion/core';
import { Box } from '@fast-ai/ui-components';
import ZoeDrIcon from '@fast-ai/dashboard-icons/src/ZoeDr';
import PropTypes from 'prop-types';

import useEventCallback from './utils/useEventCallback';
import useTwoPassRender from './utils/useTwoPassRender';
import ResponsiveIcon from './ResponsiveIcon';

const holeAnimation = keyframes`
0% { transform: scale(1,1); }
20% { transform: scale(1.4,1.4); }
30% { transform: scale(1,1); }
`;

const ZoeDrAnimation = ({ sx, size = 256, ...rest }) => {
	const isSecondPass = useTwoPassRender();

	const setPath = useEventCallback(node => {
		if (!node) {
			return;
		}

		const paths = node.getElementsByClassName('c');
		for (const path of paths) {
			const BBox = path.getBBox();
			path.style.transformOrigin = `${BBox.x + BBox.height / 2}px ${
				BBox.y + BBox.width / 2
			}px`;
		}
	});

	return (
		<Box
			sx={{
				color: 'body',
				...sx,
			}}
			{...rest}
		>
			<ResponsiveIcon
				sx={{
					overflow: 'visible',
					'.c': {
						fill: 'secondary',
						transform: 'scale(1,1)',
						animationName: isSecondPass ? holeAnimation : '',
						animationDuration: '4s',
						animationIterationCount: 'infinite',
						animationFillMode: 'both',
						animationTimingFunction: 'ease-in-out',
					},
					'.border': {
						fill: 'primary',
					},
					'.c:nth-of-type(1)': {
						animationDelay: '2s',
					},
					'.c:nth-of-type(2)': {
						animationDelay: '2.8s',
					},
					'.c:nth-of-type(3)': {
						animationDelay: '3.6s',
					},
				}}
				iconRef={setPath}
				renderIcon={ZoeDrIcon}
				size={size}
			/>
		</Box>
	);
};
ZoeDrAnimation.propTypes = {
	size: PropTypes.any,
};
export default ZoeDrAnimation;
