/* eslint-disable react/prop-types,react/display-name */
import React, { Suspense, lazy } from 'react';
import { keyframes } from '@emotion/core';
import { GatsbyImage, getImage } from 'gatsby-theme-fast-ai-sidebar';
import { graphql, useStaticQuery } from 'gatsby';
import { Box, Col, Container, Heading, Row } from '@fast-ai/ui-components';
import { Paragraph } from 'gatsby-theme-fast-ai-sidebar/src/mdxComponents';
import useJustIsInViewport from '@fast-ai/ui/utils/useJustIsInViewport';

import { GatsbyDashedCircleImage, Strong } from '../../ui';
import m from '../../intl/messages/viewLanding';
import { RichFormattedMessage } from '../../intl';

const Carousel = lazy(() => import('./ReferencesCarousel'));

const zira = {
	id: 1,
	img: 'ziraLogo',
	imgTitle: 'Zira - logo',
	name: 'Nermin Šehić',
	company: 'System Designer, ZIRA',
	description: <RichFormattedMessage {...m.refZiraDescription} />,
};
const heureka = {
	id: 2,
	img: 'frantisekSeda',
	imgTitle: 'Heureka - František Šeda - logo',
	heading: 'Heureka.cz',
	company: 'Product Head of Tribe at Heureka.cz',
	name: 'František Šeda',
	description: <RichFormattedMessage {...m.refHeurekaDescription} />,
};
const pilulka = {
	id: 3,
	img: 'jozefFilo',
	imgTitle: 'Pilulka.cz - Jozef Filo - logo',
	heading: 'Pilulka.cz',
	company: 'E-commerce product manager at Pilulka.cz',
	name: 'Jozef Filo',
	description: <RichFormattedMessage {...m.refPilulkaDescription} />,
};
const referencesData = [heureka, pilulka, zira];

const quotesAnimation = keyframes`
0% {
	transform: translateY(0%) scale(1);
}
50% {
	transform: translateY(20%) scale(1.3);
}
100% {
	transform: translateY(0%) scale(1);
}
`;
const appendQuotesToRef = el => {
	const before = document.createElement('DIV');
	before.className = 'quotes quotes-before';
	const after = document.createElement('DIV');
	after.className = 'quotes quotes-after';
	if (el) {
		const notExists = !!el.getElementsByClassName('quotes').length;

		if (!notExists && el.firstChild) {
			el.firstElementChild.prepend(before);
			el.lastElementChild.append(after);
		}
	}
};
const Reference = ({
	img,
	imgTitle,
	heading,
	data,
	name,
	company,
	description,
	sx,
	...rest
}) => (
	<Box
		sx={{
			p: { fontSize: [4, 4, 5, 5] },
			...sx,
		}}
		{...rest}
	>
		<GatsbyDashedCircleImage
			sx={{
				'.gatsby-image-wrapper': { mx: 'auto' },
			}}
		>
			<GatsbyImage image={getImage(data[img])} alt={imgTitle} />
		</GatsbyDashedCircleImage>
		{heading && <Heading variant="subHeading2">{heading}</Heading>}
		<figure>
			<Box
				as="blockquote"
				ref={appendQuotesToRef}
				sx={{
					mb: 4,
					'&:hover': {
						'.quotes': {
							animationIterationCount: 3,
							animationName: quotesAnimation,
							animationDuration: '.5s',
						},
					},
					'.quotes': {
						quotes: '"“" "”"',
						color: 'primary',
						fontSize: 8,
						lineHeight: '1px',
						fontWeight: 800,
						position: 'relative',
						top: '10px',
						display: 'inline-block',
					},
					'.quotes-after:after': {
						lineHeight: '1px',
						display: 'inline-block',
						content: 'close-quote',
					},
					'.quotes-before:after': {
						display: 'inline-block',
						lineHeight: '1px',
						content: 'open-quote',
					},
				}}
			>
				{description}
			</Box>
			<figcaption>
				<Paragraph>
					<Strong sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
						{name}
					</Strong>
					,{' '}
					<Box as="cite" sx={{ display: 'block' }}>
						{company}
					</Box>
				</Paragraph>
			</figcaption>
		</figure>
	</Box>
);
const ReferencesSection = ({ variant = 'stripesPrimary' }) => {
	const data = useStaticQuery(graphql`
		{
			ziraLogo: file(relativePath: { eq: "zira-logo.png" }) {
				childImageSharp {
					gatsbyImageData(width: 200, quality: 70, layout: CONSTRAINED)
				}
			}
			frantisekSeda: file(relativePath: { eq: "frantisek-seda.jpg" }) {
				childImageSharp {
					gatsbyImageData(width: 250, quality: 70, layout: CONSTRAINED)
				}
			}
			jozefFilo: file(relativePath: { eq: "jozef-filo.jpg" }) {
				childImageSharp {
					gatsbyImageData(width: 250, quality: 70, layout: CONSTRAINED)
				}
			}
		}
	`);

	const { ref: rootRef, shown } = useJustIsInViewport({
		threshold: 0.1,
	});
	return (
		<Box ref={rootRef} variant={variant}>
			<Container
				as="section"
				sx={{
					position: 'relative',
					textAlign: 'center',
					py: [5, 5, 6],
				}}
			>
				<Row>
					<Col span={12}>
						<Heading>
							<RichFormattedMessage {...m.referencesHeading} />
						</Heading>
					</Col>
				</Row>
				<Row sx={{ flexWrap: 'wrap' }}>
					{shown ? (
						<Suspense fallback={<div />}>
							<Carousel
								spaceBetween={50}
								slidesPerView={1}
								items={referencesData}
								renderItem={props => (
									<Reference key={props.id} data={data} {...props} />
								)}
							/>
						</Suspense>
					) : (
						<Col span={12}>
							{referencesData.map((props, i) => (
								<Reference data={data} key={i} sx={{ mb: 5 }} {...props} />
							))}
						</Col>
					)}
				</Row>
			</Container>
		</Box>
	);
};
export default ReferencesSection;
