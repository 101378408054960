/* eslint-disable max-len */
import React, { Fragment } from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<Fragment>
		<g transform="scale(1.03 1.03)">
			<g
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
				strokeWidth="2"
				data-name="Layer 2"
			>
				<path d="M16.975 5.6512l8.4852 8.4853-2.8284 2.8284-8.4853-8.4852z" />
				<path d="M24.0396 1.4112l5.6569 5.6569-5.6569 5.6568-5.6569-5.6568zM26.87 4.24l2.83-2.83M8.4782 14.1412l8.4853 8.4853-2.8284 2.8284-8.4853-8.4852z" />
				<path d="M7.0704 18.3812l5.6569 5.6569-5.6569 5.6568-5.6569-5.6568zM4.24 26.87L1.41 29.7M16.26 10.61l-3.53 3.53M20.51 14.85l-3.54 3.53" />
			</g>
		</g>
	</Fragment>,
	32,
	'Programming1Asset94'
);
