import React from 'react';
import { Box, Col, Heading, Row, Text } from '@fast-ai/ui-components';
import Icon from '@fast-ai/dashboard-icons/src/Icon';
import GraphIcon from '@fast-ai/dashboard-icons/src/GraphsAndCharts2Asset22';
import useJustIsInViewport from '@fast-ai/ui/utils/useJustIsInViewport';

import { Primary, Section, TextIn } from '../../ui';
import m from '../../intl/messages/viewLanding';
import { RichFormattedMessage } from '../../intl';

const data = [
	{
		year: '1999',
		heading: <RichFormattedMessage {...m.drTimelineGen0Heading} />,
		description: <RichFormattedMessage {...m.drTimelineGen0Description} />,
	},
	{
		year: '2010',
		heading: <RichFormattedMessage {...m.drTimelineGen1Heading} />,
		description: <RichFormattedMessage {...m.drTimelineGen1Description} />,
	},
	{
		year: '2020',
		heading: <RichFormattedMessage {...m.drTimelineGen2Heading} />,
		description: <RichFormattedMessage {...m.drTimelineGen2Description} />,
	},
];

const DrTimelineSection = ({ sx, variant }) => {
	const { ref, shown } = useJustIsInViewport();
	return (
		<Section sx={sx} variant={variant}>
			<Row>
				<Col span={12}>
					<Heading>
						<RichFormattedMessage {...m.drTimelineHeading} />
					</Heading>
				</Col>
			</Row>
			<Box
				ref={ref}
				sx={{
					position: 'relative',
					overflow: 'hidden',

					'.timeline, .arrow': {
						position: 'absolute',
						left: ['calc(25% - 60px)', 'calc(25% - 60px)', 'calc(50% - 60px)'],
						transform: 'translateX(-50%)',
					},
					'.timeline': {
						color: 'primary',
						height: '100%',
						zIndex: 100,
						bottom: '30px',
					},
					'.arrow': {
						color: 'primary',
						zIndex: 101,
						width: '32px',
						height: '32px',
						fill: 'body',
						bottom: '0',
					},
					'.item + .item': { mt: [4, 4, 5] },
				}}
			>
				<Box
					as={GraphIcon}
					size={null}
					width="25%"
					height="25%"
					viewBox="0 0 32 32"
					sx={{
						display: ['none', 'none', 'block'],
						fill: 'primary',
						position: 'absolute',
						zIndex: 102,
						left: '25%',
						top: '50%',
						transform: 'translate(-50%, -50%)',
						'path:nth-of-type(3)': { stroke: 'primary' },
					}}
				/>
				<Box
					sx={{
						position: 'absolute',
						top: 0,
						bottom: 0,
						left: ['25%', '25%', '50%'],
						transition: 'transform .9s ease-out 0.5s',
						transform: shown ? 'translateY(0%)' : 'translateY(-100%)',
					}}
				>
					<Icon
						className="timeline"
						size={null}
						height={100}
						width={8}
						preserveAspectRatio="none"
					>
						<path
							d="M 4 4 V 4 100"
							stroke="currentcolor"
							strokeWidth="8"
							fillRule="nonzero"
							strokeDasharray="4 2"
						/>
					</Icon>
					<Icon className="arrow" size={null} width={18} height={18}>
						<path
							d="M9 15L0.339743 1.63133e-06L17.6603 1.17124e-07L9 15Z"
							fill="currentcolor"
						/>
					</Icon>
				</Box>
				{data.map(({ heading, description, year }) => (
					<TextIn className="item" key={year}>
						{animationProps => (
							<Row {...animationProps}>
								<Col span={[3, 3, 6]} />
								<Col span={[9, 9, 6]} sx={{ textAlign: 'left' }}>
									<Heading sx={{ mb: [1, 1, 1] }} variant="subHeading1">
										<Primary>{year}+</Primary> {heading}
									</Heading>

									<Text as="p" sx={{ mb: 0 }}>
										{description}
									</Text>
								</Col>
							</Row>
						)}
					</TextIn>
				))}
			</Box>
		</Section>
	);
};
export default DrTimelineSection;
