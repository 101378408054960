import { Box } from '@fast-ai/ui-components';
import { GatsbyImage, getImage } from 'gatsby-theme-fast-ai-sidebar';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';

const NUMBER_OF_LAYERS = 5;
const SPEED = 2;
const MAX_SCROLL_Y = 300;
const HeroZooImage = ({ sx, ...rest }) => {
	const data = useStaticQuery(graphql`
		query {
			parallax1: file(relativePath: { eq: "dr-illustration-1.png" }) {
				childImageSharp {
					gatsbyImageData(width: 900, placeholder: BLURRED)
				}
			}
			parallax2: file(relativePath: { eq: "dr-illustration-2.png" }) {
				childImageSharp {
					gatsbyImageData(width: 900, placeholder: BLURRED)
				}
			}
			parallax3: file(relativePath: { eq: "dr-illustration-3.png" }) {
				childImageSharp {
					gatsbyImageData(width: 900, placeholder: BLURRED)
				}
			}
			parallax4: file(relativePath: { eq: "dr-illustration-4.png" }) {
				childImageSharp {
					gatsbyImageData(width: 900, placeholder: BLURRED)
				}
			}
			parallax5: file(relativePath: { eq: "dr-illustration-5.png" }) {
				childImageSharp {
					gatsbyImageData(width: 900, placeholder: BLURRED)
				}
			}
		}
	`);
	const heroWrapperRef = useRef();
	const [scroll, setScroll] = useState(0);

	useEffect(() => {
		const handler = () => {
			setScroll(window.scrollY);
		};
		window.addEventListener('scroll', handler);
		return () => window.removeEventListener('scroll', handler);
	}, []);
	return (
		<Box
			ref={heroWrapperRef}
			sx={{
				width: '100%',
				paddingBottom: '100%',
				pointerEvents: 'none',
				...sx,
			}}
			{...rest}
		>
			{Array(NUMBER_OF_LAYERS)
				.fill()
				.map((_, i) => (
					<Box
						sx={{
							position: 'absolute',
							width: '100%',
							inset: 0,
							zIndex: NUMBER_OF_LAYERS - i,
						}}
						style={{
							transform: `translateY(${
								Math.min(scroll, MAX_SCROLL_Y) / ((i + 1) * SPEED)
							}px)`,
						}}
						key={i}
					>
						<GatsbyImage image={getImage(data[`parallax${i + 1}`])} alt="" />
					</Box>
				))}
		</Box>
	);
};
export default HeroZooImage;
