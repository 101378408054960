/* eslint-disable max-len */
import React, { Fragment } from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<Fragment>
		<g
			fill="none"
			stroke="currentColor"
			strokeMiterlimit="10"
			strokeWidth="2"
			data-name="Layer 2"
		>
			<path
				strokeDasharray="2 2 2 2 500 2"
				d="M21 1.86A14.84 14.84 0 0016 1a15 15 0 00-9 27M11 30.14a14.8 14.8 0 005 .86 15 15 0 009-27"
			/>
			<path d="M2 28h5v-5M30 4h-5v5M6 14h4v6H6zM14 7h4v13h-4zM22 12h4v8h-4zM10 24h15" />
		</g>
	</Fragment>,
	32,
	'BusinessAndFinance3Asset25'
);
