/* eslint-disable max-len */
import React from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<React.Fragment>
		<path d="M29 16.0005L3 31V1L29 16.0005Z" fill="currentcolor" />
	</React.Fragment>,
	32,
	'Play'
);
