/* eslint-disable max-len */
import React, { Fragment } from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
	<Fragment>
		<g
			fill="none"
			stroke="currentColor"
			strokeMiterlimit="10"
			strokeWidth="2"
			data-name="Layer 2"
		>
			<path d="M24 5V1H7L1 7v24h17" />
			<path d="M7 1v6H1M31 19a7 7 0 10-10 6.32V31h6v-5.68A7 7 0 0031 19zM21 27h6M24 23v-4M21 19h6M23 8h2M17.63 9.97l1.74-1M28.63 8.97l1.74 1M5 28v-8M9 28V12M13 16v12" />
		</g>
	</Fragment>,
	32,
	'BusinessAndFinance1Asset25'
);
